<div *ngIf="mode === TopSearchDropdownItemMode.STOPS" class="flex flex-col text-mainColor items-center font-bold">
	Rodzaj transportu:
	<mat-radio-group
		class="text-center"
		color="primary"
		[(ngModel)]="ownTransport"
		(ngModelChange)="updateOwnTransport($event)"
		aria-label="Wybierz typ dojazdu"
	>
		<mat-radio-button class="pr-1" [value]="false"><span class="cursor-pointer">Autokarem</span></mat-radio-button>
		<mat-radio-button class="pl-1" [value]="true"
			><span class="cursor-pointer">Dojazd własny</span></mat-radio-button
		>
	</mat-radio-group>
</div>

<app-multi-choice
	*ngIf="type === DropdownType.MULTI"
	[disabled]="(ownTransport$ | async) && mode === TopSearchDropdownItemMode.STOPS"
	[_options]="options$ | async"
	[search]="{
		show: mode === TopSearchDropdownItemMode.STOPS,
		options: { placeholder: 'Wyszukaj lokalizacje' },
	}"
	[_selected]="selected$ | async"
	(filterValue)="filtersActions($event)"
	(searchUpdated)="searchingValue$.next($event)"
></app-multi-choice>

<app-top-search-counter
	*ngIf="type === DropdownType.COUNTER"
	[rows]="rows$ | async"
	[counters]="counters$ | async"
	(rowValueChange)="filtersActions($event)"
></app-top-search-counter>

<app-top-search-date-picker
	*ngIf="type === DropdownType.DATE"
	[offersDateRange]="offersDateRange$ | async"
	[selectedDateRange]="selectedDateRange$ | async"
	(selectedDateRangeChange)="filtersActions($event)"
></app-top-search-date-picker>

<div *ngIf="type !== DropdownType.COUNTER" class="flex flex-row justify-between items-center py-2">
	<button
		[disabled]="(ownTransport$ | async) && mode === TopSearchDropdownItemMode.STOPS"
		[ngClass]="{
			'hover:underline !text-mainColor':
				(ownTransport$ | async) === false || mode !== TopSearchDropdownItemMode.STOPS,
		}"
		class="w-1/2 text-lightGray"
		(click)="clearFilterValue()"
	>
		Wyczyść
	</button>
	<button (click)="closeDropdown()" class="submit-btn">Wybierz</button>
</div>
