import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopSearchComponent } from '@/new-top-search/top-search.component';
import { ButtonModule } from '@/modules/shared/button/button.module';
import { MobileWrapperModule } from '@/modules/shared/mobile-wrapper/mobile-wrapper.module';
import { TopSearchFilterItemsComponent } from '@/new-top-search/top-search-filter-items/top-search-filter-items.component';
import { TopSearchFilterComponent } from './top-search-filter/top-search-filter.component';
import { DropdownModule } from '@/common/dropdown/dropdown.module';
import { MultiChoiceComponent } from './top-search-dropdown-item/multi-choice/multi-choice.component';
import { TopSearchCounterComponent } from '@/new-top-search/top-search-dropdown-item/top-search-counter/top-search-counter.component';
import { TopSearchDatePickerComponent } from '@/new-top-search/top-search-dropdown-item/top-search-date-picker/top-search-date-picker.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CounterComponent } from '@/common/counter/counter.component';
import { TopSearchDropdownItemComponent } from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.component';
import { SearchComponent } from '@/common/search/search.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
	declarations: [
		TopSearchComponent,
		TopSearchFilterItemsComponent,
		TopSearchFilterComponent,
		MultiChoiceComponent,
		TopSearchCounterComponent,
		TopSearchDatePickerComponent,
		TopSearchDropdownItemComponent,
	],
	imports: [
		CommonModule,
		ButtonModule,
		MobileWrapperModule,
		DropdownModule,
		MatCheckboxModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		CounterComponent,
		SearchComponent,
		FormsModule,
		MatRadioModule,
	],
	exports: [TopSearchComponent],
})
export class NewTopSearchModule {}
